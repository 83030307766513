const mapMarkersData = [
  {
    pos_x: "34%",
    pos_y: "78%",
    name: "Heizungstechnik",
    Scene_id: 1,
    Link: "https://tekloth.de/heizungstechnik/",
  },
  {
    pos_x: "46%",
    pos_y: "82%",
    name: "Elektroinstallation",
    Scene_id: 2,
    Link: "https://tekloth.de/elektrotechnik/",
  },
  {
    pos_x: "37%",
    pos_y: "58%",
    name: "Automation",
    Scene_id: 3,
    Link: "https://tekloth.de/automation/",
  },
  {
    pos_x: "47%",
    pos_y: "57%",
    name: "Klimatechnik",
    Scene_id: 4,
    Link: "https://tekloth.de/klima/",
  },
  {
    pos_x: "69%",
    pos_y: "60%",
    name: "Sicherheitstechnik",
    Scene_id: 5,
    Link: "https://tekloth.de/sicherheit/",
  },
  {
    pos_x: "49%",
    pos_y: "36%",
    name: "Lüftungstechnik",
    Scene_id: 6,
    Link: "https://tekloth.de/lueftung/",
  },
  {
    pos_x: "55%",
    pos_y: "44%",
    name: "Sanitär / Bad",
    Scene_id: 7,
    Link: "https://tekloth.de/bad-sanitaer/",
  },
  {
    pos_x: "50%",
    pos_y: "84%",
    name: "Kältetechnik",
    Scene_id: 8,
    Link: "https://tekloth.de/kaelte/",
  },
  {
    pos_x: "58%",
    pos_y: "26%",
    name: "Solar",
    Scene_id: 9,
    Link: "https://tekloth-Solar.de",
  },
  {
    pos_x: "62%",
    pos_y: "66%",
    name: "Wallbox",
    Scene_id: 10,
    Link: "https://tekloth.de/wallbox/",
  },
  {
    pos_x: "54%",
    pos_y: "89%",
    name: "Haushaltsgeräte",
    Scene_id: 11,
    Link: "https://tekloth.de/haushaltsgeraete/",
  },
];

export default mapMarkersData;
