import React from "react";
import MapWrapper from "../Widgets/MapWrapper";
import MapMarker from "../Widgets/MapMarker";
import Sidebar from "../Widgets/Sidebar";
import HomeMap from "../../assets/img/maps/TEK_9999_interaktive_3D_Haus_v04_transparent.png";

import mapMarkersData from "../../Data/mapMarkersData";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Sidebar: "hidden",
      Scene: null,
      Scene_id: 0,
    };

    this.setCurrentScene = this.setCurrentScene.bind(this);
  }

  setCurrentScene(name, Scene_id) {
    if (this.state.Sidebar === "hidden") {
      this.setState({
        Sidebar: "active",
        Scene: name,
        Scene_id: Scene_id,
      });
      // Füge dem Element mit der Klasse "map-inner" die Klasse "locked" hinzu
      document.getElementsByClassName("map-inner")[0].classList.add("locked");
    } else {
      this.setState({
        Sidebar: "hidden",
      });
      // Entferne dem Element mit der Klasse "map-inner" die Klasse "locked"
      document
        .getElementsByClassName("map-inner")[0]
        .classList.remove("locked");
    }
  }

  render() {
    const markers = [
      { name: "Heizungstechnik", pos_x: "35%", pos_y: "75%" },
      { name: "Elektroinstallation", pos_x: "50%", pos_y: "80%" },
    ];

    return (
      <React.Fragment>
        <div
          onClick={(e) => {
            if (e.target.classList.value === "MapWrapper") {
              this.setState({ Sidebar: "hidden" });
              document
                .getElementsByClassName("map-inner")[0]
                .classList.remove("locked");
            }
          }}
          className="FullWidth"
        >
          <MapWrapper source={HomeMap}>
            {mapMarkersData.map((marker, index) => (
              <MapMarker
                key={index}
                pos_x={marker.pos_x}
                pos_y={marker.pos_y}
                name={marker.name}
                clickState={() =>
                  this.setCurrentScene(marker.name, marker.Scene_id)
                }
              />
            ))}
          </MapWrapper>
          <Sidebar
            Scene={this.state.Scene}
            Scene_id={this.state.Scene_id}
            Status={this.state.Sidebar}
            SidebarHandler={this.setCurrentScene}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
