import React from "react";

import "../../assets/stylesheets/scss/styles/_Sidebar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import heizung from "../../assets/img/scene/icons/heizung.png";
import automation from "../../assets/img/scene/icons/Automation-01.svg";
import bad from "../../assets/img/scene/icons/Bad-06.svg";
import elektroinstallation from "../../assets/img/scene/icons/Elektroinstallation-02.svg";
import haushaltsgeraete from "../../assets/img/scene/icons/Haushaltsgeräte-10.svg";
import kaeltetechnik from "../../assets/img/scene/icons/Kältetechnik-03.svg";
import klimatechnik from "../../assets/img/scene/icons/Klimatechnik-04.svg";
import lueftungstechnik from "../../assets/img/scene/icons/Lüftungstechnik-05.svg";
import sicherheitstechnik from "../../assets/img/scene/icons/Sicherheitstechnik-07.svg";
import solar from "../../assets/img/scene/icons/Solar-08.svg";
import wallbox from "../../assets/img/scene/icons/Wallbox-09.svg";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SidebarData from "../../Data/SidebarData";
import AccordionData from "../../Data/AccordionData";

import { BlocksRenderer } from "@strapi/blocks-react-renderer";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarData: {},
      query: "",
      imageUrl: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.Scene_id !== prevProps.Scene_id) {
      const fetchData = async () => {
        const { Scene_id } = this.props;
        let query;
        let url;

        switch (Scene_id) {
          case 1:
            query = "heizungstechnik";
            url = heizung;
            break;
          case 2:
            query = "elektroinstallation";
            url = elektroinstallation;
            break;
          case 3:
            query = "automation";
            url = automation;
            break;
          case 4:
            query = "klimatechnik";
            url = klimatechnik;
            break;
          case 5:
            query = "sicherheitstechnik";
            url = sicherheitstechnik;
            break;
          case 6:
            query = "lueftungstechnik";
            url = lueftungstechnik;
            break;
          case 7:
            query = "sanitaer-bad";
            url = bad;
            break;
          case 8:
            query = "kaeltetechnik";
            url = kaeltetechnik;
            break;
          case 9:
            query = "solar";
            url = solar;
            break;
          case 10:
            query = "wallbox";
            url = wallbox;
            break;
          case 11:
            query = "haushaltsgeraete";
            url = haushaltsgeraete;
            break;
          default:
            query = "heizungstechnik";
            url = heizung;
            break;
        }

        try {
          // Fetch sidebar data
          const sidebarResponse = await fetch(
            `https://teklothadmin.studio-horn.de/api/${query}?populate=Accordion.SubAccordion`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
              },
            }
          );
          const sidebarData = await sidebarResponse.json();

          this.setState({
            sidebarData: sidebarData.data,
            query: query,
            imageUrl: url,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }

  render() {
    const { Status, Scene_id, SidebarHandler } = this.props;
    const { sidebarData } = this.state;

    // const sidebarData = SidebarData.find((data) => data.Scene_id === Scene_id);
    // const accordionData = AccordionData.filter(
    //   (data) => data.Scene_id === Scene_id
    // );

    return (
      <React.Fragment>
        <div className={"sidebar " + Status}>
          <div className="SidebarInner">
            <div className="SidebarHeader">
              <a className="SidebarHeader__Close" onClick={SidebarHandler}>
                <FontAwesomeIcon
                  className="SidebarHeader__Icon"
                  icon={faCircleXmark}
                />
                Zurück zur Übersicht
              </a>
            </div>
            <div className="SidebarContent">
              <div className="SidebarContentHeader">
                <div className="SceneIcon">
                  <img src={this.state.imageUrl} alt={`${this.state.query}`} />
                </div>
              </div>
              <div className="SidebarContentBody">
                {/* dynamic div with scene description */}
                <div className="info">
                  <h2>{sidebarData?.attributes?.Scene}</h2>
                  <div className="devider"></div>

                  {sidebarData?.attributes?.Description !== undefined ? (
                    <div>
                      <BlocksRenderer
                        content={sidebarData?.attributes?.Description}
                      />
                    </div>
                  ) : null}

                  {sidebarData?.attributes?.Link &&
                    sidebarData?.attributes?.LinkDesc && (
                      <a
                        className="readmore"
                        href={sidebarData?.attributes?.Link}
                        target={`${
                          sidebarData?.attributes?.LinkExternal === true
                            ? "_blank"
                            : "_self"
                        }`}
                      >
                        {sidebarData?.attributes?.LinkDesc}
                      </a>
                    )}
                </div>

                <div className="accordion">
                  {sidebarData?.attributes?.Accordion?.length > 0 ? (
                    <>
                      {sidebarData?.attributes?.Accordion.map(
                        (accordion, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="AccordionHeaderContainer"
                            >
                              <p className="AccordionHeaderTitle">
                                {accordion.Header}
                              </p>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                className="d-flex flex-column"
                                component={"span"}
                              >
                                {accordion?.Content !== undefined ? (
                                  <div>
                                    <BlocksRenderer
                                      content={accordion?.Content}
                                    />
                                  </div>
                                ) : null}
                                <div>
                                  {accordion?.Link && accordion?.LinkDesc && (
                                    <a
                                      className="readmore mt-3"
                                      href={accordion?.Link}
                                      target={`${
                                        accordion?.LinkExternal === true
                                          ? "_blank"
                                          : "_self"
                                      }`}
                                    >
                                      {accordion?.LinkDesc}
                                    </a>
                                  )}

                                  {accordion?.SubAccordion?.length > 0 ? (
                                    <>
                                      {accordion?.SubAccordion.map(
                                        (subAccordion, index) => (
                                          <Accordion
                                            key={index}
                                            className="mt-3"
                                          >
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                              className="AccordionHeaderContainer"
                                            >
                                              <p className="AccordionHeaderTitle">
                                                {subAccordion.Header}
                                              </p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              <Typography
                                                className="d-flex flex-column"
                                                component={"span"}
                                              >
                                                {subAccordion?.Content !==
                                                undefined ? (
                                                  <div>
                                                    <BlocksRenderer
                                                      content={
                                                        subAccordion?.Content
                                                      }
                                                    />
                                                  </div>
                                                ) : null}
                                                <div>
                                                  {subAccordion?.Link &&
                                                    subAccordion?.LinkDesc && (
                                                      <a
                                                        className="readmore mt-3"
                                                        href={
                                                          subAccordion?.Link
                                                        }
                                                        target={`${
                                                          subAccordion?.LinkExternal ===
                                                          true
                                                            ? "_blank"
                                                            : "_self"
                                                        }`}
                                                      >
                                                        {subAccordion?.LinkDesc}
                                                      </a>
                                                    )}
                                                </div>
                                              </Typography>
                                            </AccordionDetails>
                                          </Accordion>
                                        )
                                      )}{" "}
                                    </>
                                  ) : null}
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}{" "}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Sidebar;
